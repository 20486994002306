<template>
    <template v-if="moduleLoaded">
        <div :id="uid" ref="froalaEditorRef" class="h-100 o365-froala w-100" :class="{ 'rounded-borders': roundedBorders }"></div>
        <template v-if="filesDataObject">
            <Progress :fileUpload="filesDataObject.fileUpload" :progress="progress" ref="modalProgress"></Progress>
            <OModal :title="$t('Images')" ref="modalImages" class="modal modal-xl">
                <div class="modal-body">
                    <OTabs @onShown="handleImageTabChange">
                        <OTab :title="$t('Uploaded images')" active class="overflow-hidden">
                            <UploadDrop :dataObject="filesDataObject">
                                <div class="mt-3 row">
                                    <div class="col-12">
                                        <div class="mb-1">
                                            <label class="form-label" for="image-url"> {{ $t("Image URL") }} </label>
                                            <input class="form-control" id="image-url" type="text" :value="typeof filesDataObject.current.index === 'undefined' ? '' : `/nt/api/file/view/${filesDataObject.viewName}/${filesDataObject.current.PrimKey}/${filesDataObject.current.FileName}`" :disabled="typeof filesDataObject.current.index === 'undefined'">
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="d-inline-flex flex-wrap align-items-center overflow-auto w-100" style="max-height: 650px;" @scroll="infiniteScrollImages($event, filesDataObject)">
                                    <div v-for="image in filesDataObject.data" :key="image.ID" class="p-1 m-1 position-relative select-image" @click="filesDataObject.setCurrentIndex(image.index)">
                                        <div class="position-absolute top-50 start-50 translate-middle bg-white py-0 px-1 rounded">
                                            <i v-if="image.index === filesDataObject.current.index" style="font-size: 150%;" class="bi bi-check2-circle"></i>
                                        </div>
                                        <img class="img-thumbnail" :class="{ 'selected-image': image.index === filesDataObject.current.index }" :src="`/nt/api/file/view/${filesDataObject.viewName}/` + image.PrimKey + '/' + image.FileName + '?scale=thumbnail'">
                                    </div>
                                </div>
                            </UploadDrop>
                        </OTab>
                        <OTab :title="$t('Central images archive')">
                            <div class="mt-3">
                                <div class="mb-3">
                                    <label class="form-label" for="image-filename"> {{ $t("FileName") }} </label>
                                    <input class="form-control" id="image-filename" type="text" v-model="dsImagesArchive.current.FileName" readonly>
                                </div>
                            </div>
                            <hr />
                            <div class="d-inline-flex flex-wrap align-items-center overflow-auto w-100" style="max-height: 748px;" @scroll="infiniteScrollImages($event, dsImagesArchive)">
                                <div v-for="image in dsImagesArchive.data" :key="image.ID" class="p-1 m-1 position-relative select-image" @click="dsImagesArchive.setCurrentIndex(image.index)">
                                    <div class="position-absolute top-50 start-50 translate-middle bg-white py-0 px-1 rounded">
                                        <i v-if="image.index === dsImagesArchive.current.index" style="font-size: 150%;" class="bi bi-check2-circle"></i>
                                    </div>
                                    <img class="img-thumbnail" :src="'/nt/api/file/view/sviw_Image_Images2/' + image.PrimKey + '/' + image.FileName + '?scale=thumbnail'">
                                </div>
                            </div>
                        </OTab>
                        <OTab :title="$t('Insert image from URL')">
                            <div class="mt-3">
                                <div class="mb-3">
                                    <label class="form-label" for="image-filename"> {{ $t("URL") }} </label>
                                    <input class="form-control" id="image-filename" type="text" v-model="insertImageUrl">
                                </div>
                            </div>
                        </OTab>
                    </OTabs>
                </div>
                <div class="modal-footer">
                    <div v-if="imageModalTabNo === 0">
                        <button @click="insertImage(filesDataObject, null)" class="btn btn-sm btn-primary">{{ $t("Insert image") }}</button>
                        <FileUpload :dataObject="filesDataObject" multiple="multiple" class="btn btn-sm btn-outline-primary mx-2">{{ $t("Upload image") }}</FileUpload>
                        <button class="btn btn-sm btn-outline-primary" :class="{ disabled: !filesDataObject.current.hasChanges }" @click="filesDataObject.save()">{{ $t("Save") }}</button>
                    </div>
                    <div v-if="imageModalTabNo === 1">
                        <button @click="insertImage(dsImagesArchive, null)" class="btn btn-sm btn-primary">{{ $t('Insert image') }}</button>
                    </div>
                    <div v-if="imageModalTabNo === 2">
                        <button @click="insertImage(null, insertImageUrl)" class="btn btn-sm btn-primary">{{ $t('Insert image') }}</button>
                    </div>
                </div>
            </OModal>
        </template>
    </template>
</template>

<script setup lang="ts">
import { DataObject } from 'o365-dataobject';
import { importUtils, logger, Debouncer } from 'o365-utils';
import { ref, nextTick, onBeforeUnmount, watch } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { OFileUploadButton as FileUpload, OUploadDrop as UploadDrop, OFileUploadProgress as Progress } from "o365-fileupload";
import { $t } from "o365-utils";
import { OModal, OTabs, OTab } from 'o365-ui-components';

export interface ICustomElement {
    type: "command" | "quickInsert",
    name: string,
    options: {
        [key: string]: any
    }
}

export interface IProps {
    dataObject?: DataObject,
    filesDataObject?: DataObject,
    simple?: boolean,
    editorOptions?: any,
    customElements?: ICustomElement[],
    toolbarButtons?: Record<string, { buttons: string[], buttonsVisible?: number, align?: string }>,
    roundedBorders?: boolean,
};

export interface IEmits {
    (e: 'save', value?: string): void,
};

const props = defineProps<IProps>();

const emit = defineEmits<IEmits>();

const model = defineModel<string>();

let internalEditorValue: null | string = null; // Stop deps traverse loops

watch(() => model.value, (pNewValue) => {
    if (froalaEditor && internalEditorValue != pNewValue) {
        froalaEditor?.html?.set(pNewValue);
        internalEditorValue = pNewValue;
    }
});

const uid = `froala-editor-${crypto.randomUUID()}`
const cleanupTokens: (() => void)[] = [];
const moduleLoaded = ref(false);
const modalImages = ref();
const imageModalTabNo = ref(0);
const insertImageUrl = ref("");
const progress = ref({ total: 0, progress: 0 });
const modalProgress = ref();

const autosaveDebouncer = new Debouncer(1000);

const editorObj = ref();
loadFroala().then(() => {
    moduleLoaded.value = true;
    return nextTick();
}).then(() => {
    initCustomElements();
    if (props.filesDataObject) {
        initDefaultFileUploadElements();
    }
}).then(() => {
    editorObj.value = initEditor();
    if (props.filesDataObject) {
        initFileUpload();
    }
}).catch(ex => {
    logger.error(ex);
});


let froalaEditor = null;
const froalaEditorRef = ref<HTMLElement>();

function initFileUpload() {
    editorObj.value.el.addEventListener("paste", (e: ClipboardEvent) => {
        if (e.clipboardData?.files.length! > 0) {
            uploadFiles(e.clipboardData?.files);
        }
    });
}

function initDefaultFileUploadElements() {
    function openImagesModal() {
            const id = this.el.parentElement.parentElement.id;
            const instance = froalaInstances.get(id);
            if (instance) {
                const instanceProps = instance.getProps();
                const imageDs = instance.getImagesDataObject();
                const imagesModal = instance.getModalImages();
                instanceProps.filesDataObject.load();
                imageDs.load();
                imagesModal.show();
            }
    }
    FroalaEditor.DefineIcon("imageButton", {NAME: "insertImage", SVG_KEY: "insertImage"});
    FroalaEditor.RegisterCommand('customImages', {
        title: $t("Images"),
        focus: false,
        undo: true,
        icon: "insertImage",
        refreshAfterCallback: true,
        callback: openImagesModal
    });
    FroalaEditor.RegisterQuickInsertButton('quickInsertImage', {
        icon: "insertImage",
        title: $t("Images"),
        callback: openImagesModal,
        undo: true
    });
}

async function uploadFiles(files) {
    if (!props.filesDataObject) {
        console.error("Could not upload file because filesDataObject was not passed as a prop");
        return;
    }
    const data = await props.filesDataObject.fileUpload.upload({ files });
    data.forEach(item => {
        editorObj.value.image.insert(`/nt/api/file/view/${props.filesDataObject.uniqueTable}/${item.PrimKey}/${item.FileName}`);
    });
    if (props.dataObject?.save) props.dataObject.save();
}

function initCustomElements() {
    if (!props.customElements) {
        return;
    }

    for (let elem of props.customElements) {
        switch (elem.type) {
            case "iconTemplate":
                FroalaEditor.DefineIconTemplate(elem.name, elem.options.template);
                /* example:
                    type: "iconTemplate",
                    name: "bootstrap",
                    options: {
                        template: '<i class="bi bi-[NAME]"></i>'
                    }
                */
                break;

            case "icon":
                FroalaEditor.DefineIcon(elem.name, elem.options);
                /* example:
                    type: "icon",
                    name: "aiAssistant",
                    options: {
                        NAME: 'robot',
                        template: 'bootstrap'
                    }
                */
                break;

            case "command":
                FroalaEditor.RegisterCommand(elem.name, elem.options);
                break;

            case "quickInsert":
                FroalaEditor.RegisterQuickInsertButton(elem.name, elem.options);
                break;
        }
    }
}

const handleImageTabChange = (tabs) => {
    const target = tabs.activeTab.dataset["bsTarget"];
    imageModalTabNo.value = parseInt(target.charAt(target.length - 1));
};

const infiniteScrollImages = (e: any, dataObject: DataObject) => {
    const clientHeight = e.target?.clientHeight + 1;
    const scrollHeight = e.target?.scrollHeight;
    const scrollTop = e.target?.scrollTop;

    if (scrollTop + clientHeight >= scrollHeight && dataObject) {
        dataObject.dynamicLoading.loadNextPage();
    }
};

const insertImage = (pDO: DataObject, pURL: string | null) => {
    editorObj.value.image.insert(pURL || `/nt/api/file/view/${pDO.viewName}/${pDO.current.PrimKey}/${pDO.current.FileName}`);
    modalImages.value.hide();
    if (props.dataObject?.save) props.dataObject.save();
};

function getDefaultOptions() {
    return {
        height: '100%',
        attribution: false,
        useClasses: true,
        pastePlain: true,
        wordPasteKeepFormatting: false,
        imagePaste: false, // Because the event returns img as base64, custom event listener created instead
        imageStyles: {
            "rounded": "Rounded",
            "border": "Border",
        },
        charCounterCount: false,
        theme: "dark",
        toolbarInline: false,
        videoUpload: false,
        imageMove: true,
        saveInterval: 1000,
        fontSizeSelection: true,
        quickInsertButtons: ["quickInsertImage", "video", "embedly", "table", "ul", "ol", "hr"],
        imageEditButtons: ["imageAlign", "imageCaption", "imageRemove", "|", "imageLink", "linkOpen", "linkEdit", "linkRemove", "-", "imageDisplay", "imageStyle", "imageAlt", "imageSize"],
        fontSize: ["18", "20", "22", "24", "26", "28", "30", "32", "34", "36", "40", "48", "60", "72", "96"],
        htmlRemoveTags: ["script"],
        pasteDeniedAttrs: ["id"],
        shortcutsEnabled: ["show", "bold", "italic", "underline", "indent", "outdent", "undo", "redo", "createLink"], // removed strikeThrough shortcut, because it uses Ctrl + S
        paragraphFormat: {
            N: "Normal",
            H1: "Heading 1",
            H2: "Heading 2",
            H3: "Heading 3",
            H4: "Heading 4",
            PRE: "Code"
        },
        paragraphFormatSelection: true,
        paragraphStyles: {
            "fr-text-gray": "Gray",
            "fr-text-bordered": "Bordered",
            "fr-text-spaced": "Spaced",
            "fr-text-uppercase": "Uppercase"
        },
        toolbarButtons: {
            moreText: {
                buttons: ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "fontFamily", "fontSize", "textColor", "backgroundColor", "inlineClass", "inlineStyle", "clearFormatting"]
            },
            
            moreParagraph: {
                buttons: ["paragraphFormat", "customLeadStyle", "alignLeft", "alignCenter", "formatOLSimple", "alignRight", "alignJustify", "formatOL", "formatUL", "paragraphStyle", "lineHeight", "outdent", "indent", "quote"],
                buttonsVisible: 2
            },
            moreRich: {
                buttons: ["trackChanges", "insertLink", 'customImages', "insertTable", "emoticons", "fontAwesome", "specialCharacters", "embedly", "insertHR"],
                buttonsVisible: 4
            },
            moreMisc: {
                buttons: ["undo", "redo", "customSave", "fullscreen", "print", "spellChecker", "selectAll", "html", "help"],
                align: "right",
                buttonsVisible: 3
            },
            trackChanges: {
                buttons: ["showChanges", "applyAll", "removeAll", "applyLast", "removeLast"],
                buttonsVisible: 0
            }
        },
    };
}
function getSimpleOptions() {
    initSimpleMode();

    return {
        height: '100%',
        attribution: false,
        useClasses: true,
        pastePlain: true,
        wordPasteKeepFormatting: false,
        imagePaste: false, // Because the event returns img as base64, custom event listener created instead
        imageStyles: {
            "rounded": "Rounded",
            "border": "Border",
        },
        charCounterCount: false,
        theme: "dark",
        toolbarInline: false,
        videoUpload: false, 
        imageMove: true,
        saveInterval: 1000,
        fontSizeSelection: true,
        quickInsertEnabled: false,
        imageEditButtons: ["imageAlign", "imageCaption", "imageRemove", "|", "imageLink", "linkOpen", "linkEdit", "linkRemove", "-", "imageDisplay", "imageStyle", "imageAlt", "imageSize"],
        htmlRemoveTags: ["script"],
        pasteDeniedAttrs: ["id"],
        shortcutsEnabled: ["show", "bold", "italic", "underline", "indent", "outdent", "undo", "redo", "createLink"], // removed strikeThrough shortcut, because it uses Ctrl + S
        paragraphFormat: {
            N: "Normal",
            H3: "Header", 
            H4: "Sub Header",
        },
        paragraphFormatSelection: true,
        toolbarButtons: {
            moreText: {
                buttons: ["bold", "italic", "underline", "strikeThrough"],
                buttonsVisible: 5
            },
            moreParagraph: {
                buttons: ['paragraphFormat', "formatOLSimple", "formatUL"],
                buttonsVisible: 4
            },
            moreRich: {
                buttons: ["insertLink", "insertTable", 'customImages',],
                buttonsVisible: 3
            },
            moreMisc: {
                buttons: ["undo", "redo", "html" ],
                align: "right",
                buttonsVisible: 4
            },
        },
    };
}

var isDirty = false;

function initEditor() {
    let options: Record<string, any> = props.simple ? getSimpleOptions() : getDefaultOptions();

    if (props.editorOptions) {
        options = {
            ...options,
            ...props.editorOptions
        };
    }

    if (props.toolbarButtons) {
        options.toolbarButtons = props.toolbarButtons;
    }

    options.key = '1CC3kD9B5E4F6G4F3bHIMFF1EWBXIJb1BZLZFh1i1MXQLjE4C3F3I3B4D6C6E3C3F2==';
   
    if (options.events == null) { options.events = {}; }

    options.events['contentChanged'] = () => {
        if (froalaEditor) {
            if(props.simple){
                isDirty = true;
                autosaveDebouncer.run(() => {
                    if (isDirty) {
                        isDirty = false;
                        emit('save', model.value);
                    }
                });
            }
            internalEditorValue = froalaEditor.html.get()
            model.value = internalEditorValue;
        }
    }

    if(!props.simple){
        options.events['save.before'] = () => {
            emit('save', model.value);
        }
    }

    if (props.filesDataObject) {
        options.events["image.beforeUpload"] = (files) => {
            props.filesDataObject.fileUpload.upload({
                files: files,
                onProgress: (_progress) => {
                    progress.value = _progress;
                    if (modalProgress.value && !_progress.completed){
                        modalProgress.value.modal.show();
                    }
                },
                onCompleted: (_insertedFiles) => {
                    modalProgress.value.modal.hide();
                    props.filesDataObject.load();
                    for (let i = 0; i < _insertedFiles.length; i++) {
                        insertImage(null, `/nt/api/file/view/${props.filesDataObject.viewName}/${_insertedFiles[i].PrimKey}/${_insertedFiles[i].FileName}`);
                    }
                }
            });
            return false;
        }
    }    


    froalaEditor = new FroalaEditor(froalaEditorRef.value, options, (a, b, c) => {
        froalaEditor.html.set(model.value ?? '');
        
        if(options.readOnly){
            froalaEditor.edit.off();
        }
        
        internalEditorValue = model.value ?? '';
    });

    froalaInstances.set(uid, {
        froalaEditor,
        getProps: () => props,
        getImagesDataObject: () => dsImagesArchive,
        getModalImages: () => modalImages.value
    });

    return froalaEditor;
}

const dsImagesArchive = getOrCreateDataObject({
    "id": "dsImagesArchive",
    "viewName": "sviw_Image_Images2",
    "distinctRows": false,
    "fields": [
        {
            "name": "ID",            
            "type": null
        },
        {
            "name": "FileName",            
            "type": null
        },
        {
            "name": "Description",            
            "type": null
        },
        {
            "name": "PrimKey",            
            "type": null
        },
        {
            "name": "Created",
            "sortOrder": 1,
            "sortDirection": "desc",
            "type": null
        }
    ],    
    "maxRecords": 30,
    "dynamicLoading": false,
});

onBeforeUnmount(() => {
    cleanupTokens.forEach(ct => ct);
    froalaInstances.delete(uid);
});

function updateDirtyState(pDirty: boolean) {
    isDirty = pDirty;
}

defineExpose({ froalaEditor: editorObj, updateDirtyState  });
</script>

<script lang="ts">
let FROALA_INIT_PROMISE: Promise<void> | null = null;
const froalaInstances = new Map<string, any>();
async function loadFroala() {
    if (FROALA_INIT_PROMISE == null) {
        let resolve = () => { };
        let reject = (pEx: Error) => { };
        FROALA_INIT_PROMISE = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        const { loadScript, loadStyle } = importUtils;
        loadScript('froala-editor/js/froala_editor.pkgd.min.js').then(() => {
            return loadScript('froala-editor/js/plugins/word_paste.min.js');
        }).then(() => {
            return loadStyle('froala-editor/css/froala_editor.pkgd.min.css');
        }).then(() => {

            resolve();
        }).catch((ex) => {
            reject(ex);
        });

        return FROALA_INIT_PROMISE;
    } else {
        return FROALA_INIT_PROMISE;
    }
}

let simpleButtonsRegistered = false;
function initSimpleMode() {
    if (simpleButtonsRegistered || FroalaEditor == null) { return;}
    simpleButtonsRegistered = true;
    FroalaEditor.DefineIconTemplate('bootstrap', '<i class="bi [NAME]" style="font-size:large;"></i>');

    // FroalaEditor.DefineIcon('headingN', { NAME: 'Normal', template: 'text' });
    FroalaEditor.RegisterCommand('Normal', {
        title: 'Normal',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.paragraphFormat.apply('P');
        }
    });

    FroalaEditor.DefineIcon('headingH4', { NAME: 'bi-type-h4', template: 'bootstrap' });
    FroalaEditor.RegisterCommand('headingH4', {
        title: 'Main Heading',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.paragraphFormat.apply('H4');
        }
    });

    FroalaEditor.DefineIcon('headingH5', { NAME: 'bi-type-h5', template: 'bootstrap' });
    FroalaEditor.RegisterCommand('headingH5', {
        title: 'Sub Heading',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.paragraphFormat.apply('H5');
        }
    });

    FroalaEditor.DefineIcon('unorderedList', { NAME: 'list-ul', SVG_KEY: 'unorderedList' });
    FroalaEditor.RegisterCommand('unorderedList', {
        title: 'Unordered List',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.commands.exec('formatUL');
        }
    });
}


</script>

<style>
.o365-froala .fr-wrapper h1 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}

/* for prop: roundedBorders */
.o365-froala.rounded-borders .fr-wrapper {
    border-top: 1px solid var(--bs-border-color) !important;
    border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0 !important;
    border-color: var(--bs-border-color) !important;
    /* background: var(--bs-gray-200) !important; */
}

.o365-froala.rounded-borders .fr-element {
    padding: .375rem .75rem !important;
}

.o365-froala.rounded-borders .fr-second-toolbar {
    border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius) !important;
    border-color: var(--bs-border-color) !important;
    /* background: var(--bs-gray-200) !important; */
}

.o365-froala.rounded-borders .fr-toolbar {
    display: none !important;
}
</style>